const calculateSpace = (gridHeight: number, length: number) => {
  const spaces = new Array(length).fill('');

  return spaces.map((_space, index) => {
    const newSpace = index * gridHeight;
    return newSpace;
  });
};

const space = calculateSpace(5, 264);
const sizes = calculateSpace(5, 264);

export { space, sizes };
