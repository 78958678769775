const forms = {
  checkbox: {},
  input: {
    variant: 'text.default',
    borderRadius: 0,
    height: 7,

    '&::placeholder': {
      color: 'gray.6',
    },
  },
};
export { forms };
