import { CapsizeStyles } from 'capsize';

const buildCapsizeStyles = (allCapsizeStyles: CapsizeStyles[]) => {
  const createStyleList = (input: CapsizeStyles[]) => {
    const styles = {};

    Object.keys(input[0]).forEach((property) => {
      if (
        // @ts-ignore
        typeof input[0][property] === 'string' ||
        // @ts-ignore
        typeof input[0][property] === 'number'
      ) {
        if (!styles || !styles[property]) {
          // @ts-ignore
          styles[property] = [];
        }

        input.forEach((_inputStyles, index) => {
          // @ts-ignore
          styles[property].push(input[index][property]);
        });

        // clean up values which do not have different sizes in different sizes anyway
        // making them a single string again
        if (
          // @ts-ignore
          styles[property].every(
            // @ts-ignore
            (val: string, _: number, array: IStylesObject) => array[0] === val
          )
        ) {
          // @ts-ignore
          // eslint-disable-next-line prefer-destructuring
          styles[property] = styles[property][0];
        }
      } else {
        const nestedStyles = input.map(
          // @ts-ignore
          (capsizeStyles) => capsizeStyles[property]
        );
        styles[property] = createStyleList(nestedStyles);
      }
    });
    return styles;
  };

  return createStyleList(allCapsizeStyles);
};

export default buildCapsizeStyles;
