const shades = {
  primary: '#0A253E',
  secondary: 'rgb(247, 147, 30)',
  white: 'rgb(251, 252, 254)',
  error: '#f7441e',
  gray: {
    _default: 'rgb(28, 28, 28)',
    1: 'rgb(28, 28, 28)',
    2: 'rgb(66, 66, 66)',
    3: 'rgb(92, 92, 92)',
    4: 'rgb(130, 130, 130)',
    5: 'rgb(168, 168, 168)',
    6: 'rgb(194, 194, 194)',
    7: 'rgb(233, 233, 233)',
    // i know, and i donot want to speak about it
    8: 'rgb(255, 255, 255)',
  },
  link: 'rgb(10, 37, 62)',
  icon: 'rgb(10, 37, 62)',
  text: 'rgb(28, 28, 28)',
  focus: 'primary',
};

const colors = {
  ...shades,
  dateline: shades.gray[4],
  subHeadline: shades.gray[5],
  color: shades.white,
  background: shades.white,
};

export default colors;
