import { IStylesObject } from '@motormusic/shared/types/objects';

const links: IStylesObject = {
  default: {
    textDecoration: 'underline',
    transition: 'color ease-in-out 0.2s',
    '&:link, &:visited': {
      color: 'link',
    },
    '&:hover, &:focus, &.active': {
      color: 'secondary',
    },
  },
  simple: {
    variant: 'links.default',
    textDecoration: 'none',
  },
  bold: {
    fontWeight: 'bold',
  },
  nav: {
    fontWeight: 'bold',
    color: 'inherit',
    textDecoration: 'none',
  },
};

export { links };
