import { buttons } from './buttons';
import colors from './colors';
import { forms } from './forms';
import { grids } from './layout';
import { links } from './links';
import { sizes, space } from './spacer';
import { styles } from './styles';
import { transitions } from './transitions';
import { fonts, fontWeights, text } from './typography';
import { Theme } from 'theme-ui'

const theme: Theme = {
  colors,
  text,
  fontWeights,
  fonts,
  forms,
  grids,
  links,
  space,
  sizes,
  styles,
  buttons,
  transitions,
};

const darkTheme: Theme = {
  text: {
    ...text,
    headline2: {
      ...text.headline2,
      color: 'white',
    },
  },
  colors: {
    ...theme.colors,
    link: colors.gray['4'],
    icon: 'white',
    text: colors.gray['5'],

    dateline: colors.gray['4'],
    subHeadline: colors.gray['5'],
    title: colors.white,
    background: colors.gray['1'],
    focus: colors.white,
  },
};

export default theme;

export { darkTheme };
