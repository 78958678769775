const grids = {
  contentGrid: {
    maxWidth: 256,

    gridTemplateColumns: [
      'repeat(4, 1fr)',
      `repeat(12, minmax(0, calc(${(256 - 11 * 5) * 5}px  / 12)))`,
    ],

    gap: [2, 5],
    alignItems: 'flex-start',
  },
};

export { grids };
