import { createStyleObject } from '@capsizecss/core';
import fontMetrics from '@capsizecss/metrics/merriweatherSans';

import buildCapsizeStyles from '../utils/buildCapsizeStyles';

const fonts = {
  body: 'Merriweather Sans',
  heading: 'Merriweather Sans',
};

const text = {
  default: {
    fontFamily: 'body',
    fontWeight: 'light',

    ...createStyleObject({
      fontMetrics,
      capHeight: 10.4,
      lineGap: 12.7,
    }),
  },
  dateline: {
    fontFamily: 'body',
    textTransform: 'uppercase',
    fontWeight: 'light',
    color: 'dateline',
    pb: 2,
    display: 'block',
    ...createStyleObject({
      fontMetrics,
      capHeight: 7.45,
      lineGap: 5.6,
    }),
  },
  subHeadline: {
    fontFamily: 'body',
    fontStyle: 'italic',
    fontWeight: 'light',
    color: 'text',
    ...createStyleObject({
      fontMetrics,
      capHeight: 14.9,
      lineGap: 13.1,
    }),
  },
  headline1: {
    fontFamily: 'body',
    ...createStyleObject({
      fontMetrics,
      capHeight: 22.3,
      lineGap: 15.7,
    }),
  },
  headline2: {
    fontFamily: 'body',
    color: 'primary',
    ...createStyleObject({
      fontMetrics,
      capHeight: 19.3,
      lineGap: 13.7,
    }),
  },
  headline3: {
    fontFamily: 'body',
    ...createStyleObject({
      fontMetrics,
      capHeight: 16.3,
      lineGap: 11.7,
    }),
  },
  headline3News: {
    fontFamily: 'body',

    ...buildCapsizeStyles([
      createStyleObject({
        fontMetrics,
        capHeight: 13.4,
        lineGap: 9.7,
      }),
      createStyleObject({
        fontMetrics,
        capHeight: 13.4,
        lineGap: 9.7,
      }),
      createStyleObject({
        fontMetrics,
        capHeight: 16.3,
        lineGap: 11.7,
      }),
    ]),
  },
  headline4: {
    fontFamily: 'body',
    ...createStyleObject({
      fontMetrics,
      capHeight: 13.4,
      lineGap: 9.7,
    }),
  },
  headline5: {
    fontFamily: 'body',
    color: 'primary',
    ...createStyleObject({
      fontMetrics,
      capHeight: 11.9,
      lineGap: 8.1,
    }),
  },
  small: {
    fontFamily: 'body',
    fontWeight: 'light',
    ...createStyleObject({
      fontMetrics,
      capHeight: 8.9,
      lineGap: 10.1,
    }),
  },
  legal: {
    fontFamily: 'body',
    fontWeight: 'light',
    ...createStyleObject({
      fontMetrics,
      capHeight: 7.4,
      lineGap: 5.6,
    }),
  },
  blockquote: {
    backgroundColor: 'secondary',
  },
  error: {
    variant: 'text.default',
    color: 'error',
  },
};

const fontWeights = {
  light: '300',
  medium: '400',
  bolder: '500',
  heading: '400',
};

export { text, fonts, fontWeights };
