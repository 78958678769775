const buttons = {
  primary: {
    '&, &:visited, &:link': {
      cursor: 'pointer',
      color: 'white',
      bg: 'primary',
      textDecoration: 'none',
      borderRadius: 0,
    },
    '&:hover': {
      bg: 'secondary',
      color: 'gray.1',
    },
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.8,
      bg: 'gray.5',
    },
  },
  secondary: {
    color: 'background',
    bg: 'secondary',
  },
  text: {
    color: 'primary',
    bg: 'transparent',
  },
};

export { buttons };
