const styles = {
  root: {
    '*:focus-visible': {
      outlineColor: 'focus',
    },
    '*': {
      boxSizing: 'border-box',
    },
    body: {
      margin: 0,
      padding: 0,
    },
  },
  a: {
    variant: 'links.default',
    color: 'text',
  },
};

export { styles };
